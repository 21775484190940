import "./About01.css";
import picture01 from "../img/cont/1.png";
import picture02 from "../img/cont/Suiamese2.png";

function About01() {
  return (
    <div id="about" className="About01 section">
      <div className="container">
        <div className="About01-box">
          <h2>
            Join the SUI revolution! A fun and community-driven memecoin on the
            SUI blockchain.
          </h2>
          <p>
            Our mission is to bring joy, laughter, and a touch of feline fun to
            the world of crypto. We envision a future where SUIAMESE is a
            household name in the crypto world, known for its vibrant community,
            playful spirit, and innovative initiatives.
          </p>

          <div className="ca">
            <h3>CA:</h3>
            <p>
              0x087f6cc77bd34cc94f6fd2d217e12b015bc8875c843b624eab7086deca29ce12::suiamese::SUIAMESE
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About01;
