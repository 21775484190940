import "./Stats01.css";
import picture01 from "../img/cont/5.png";
import picture02 from "../img/cont/3.png";

function Stats01() {
  return (
    <div id="token" className="Stats01 section">
      <div className="container">
        <div className="Stats01-box">
          <div>
            <h3>Renounced</h3>
            <p>
              Full control in your hands. We've renounced ownership of the
              $SUIAMESE contract.
            </p>
          </div>

          <div>
            <h3>Burnt LP</h3>
            <p>
              The LP has been burned, for a stable and decentralized trading
              experience.
            </p>
          </div>

          <div>
            <h3>Zero Taxes</h3>
            <p>
              Buy, sell, and meme without limits. Enjoy zero transaction fees!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats01;
