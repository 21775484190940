import "./HowTo01.css";
import picture01 from "../img/cont/5.png";
import picture02 from "../img/cont/5.png";

function HowTo01() {
  return (
    <div className="HowTo01 section">
      <div className="container">
        <div className="HowTo01-box">
          <img src={picture01} alt="picture"></img>

          <div>
            <div>
              <h3> 1</h3>
              <div>
                <p>
                  <span>Get a wallet </span>
                </p>
                <p> Get a SUI wallet from sui.io</p>
              </div>
            </div>
            <div>
              <h3> 2</h3>
              <div>
                <p>
                  <span>Bridge funds</span>
                </p>
                <p> Bridge from ANY chain to SUI.</p>
              </div>
            </div>
            <div>
              <h3> 3</h3>
              <div>
                <p>
                  <span>Buy on DEX</span>
                </p>
                <p> Buy SUIAMESE at Ocean Dex</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowTo01;
