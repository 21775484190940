import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navbar01 from "./components/Navbar01";
import Hero01 from "./components/Hero01";
import About01 from "./components/About01";
import Stats01 from "./components/Stats01";
import Token01 from "./components/Token01";
import HowTo01 from "./components/HowTo01";
import Join01 from "./components/Join01";
import Footer01 from "./components/Footer01";

import BgBush from "./components/BgBush";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Navbar01 />
    <Hero01 />
    <BgBush />
    <About01 />
    <Stats01 />
    <HowTo01 />
    <BgBush />
    <Token01 />
    <Join01 />

    <Footer01 />
  </React.StrictMode>
);
