import "./Token01.css";
import picture01 from "../img/cont/1.png";
import picture02 from "../img/cont/3.png";

function Token01() {
  return (
    <div id="token" className="Token01 section">
      <div className="container">
        <div className="Token01-box">
          <div>
            <img src={picture01} alt="picture"></img>
          </div>

          <div>
            <div className="ca">
              <h3>CA:</h3>
              <p>
                0x087f6cc77bd34cc94f6fd2d217e12b015bc8875c843b624eab7086deca29ce12::suiamese::SUIAMESE
              </p>
            </div>
            <div>
              <h3>Symbol:</h3>
              <p>$SUIAMESE</p>
            </div>
            <div>
              <h3>Tax:</h3>
              <p>0%</p>
            </div>
            <div>
              <h3>Supply:</h3>
              <p>100 M</p>
            </div>
            <div>
              <h3>LP:</h3>
              <p>Burnt</p>
            </div>

            <div className="btnFlex">
              <a
                href="https://suivision.xyz/coin/0x087f6cc77bd34cc94f6fd2d217e12b015bc8875c843b624eab7086deca29ce12::suiamese::SUIAMESE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn01">SUIVISION</button>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <button className="btn02">CHART</button>
              </a>
            </div>
          </div>
          <div>
            <img src={picture02} alt="picture"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token01;
