import "./Footer01.css";
import picture01 from "../img/bg/bg-f.png";

function Footer01() {
  return (
    <header className="Footer01">
      <div className="Footer01-logo">
        <p>SUIAMESE @2024</p>
      </div>

      <ul className="Footer01-links">
        <li>
          <a href="/"></a>
        </li>
      </ul>
    </header>
  );
}

export default Footer01;
