import "./Join01.css";
import picture01 from "../img/cont/6.png";

function Join01() {
  return (
    <div id="join" className="Join01 section">
      <div className="container">
        <div className="Join01-box">
          <div>
            <h2>Become a SUIAMESE</h2>
            <p>
              Join our growing community and be part of something special.
              Connect with us on:
            </p>

            <div className="btnFlex">
              <a href="" target="_blank" rel="noopener noreferrer">
                <button className="btn01">X/TWITTER</button>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <button className="btn02">TELEGRAM</button>
              </a>
            </div>
          </div>

          <img src={picture01} alt="picture"></img>
        </div>
      </div>
    </div>
  );
}

export default Join01;
