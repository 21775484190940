import "./BgBush.css";
import picture02 from "../img/bg/bg-bush.png";

function BgBush() {
  return (
    <div id="about" className="BgBush section">
      <img className="bg-bush" src={picture02} alt="picture"></img>
    </div>
  );
}

export default BgBush;
