import "./Hero01.css";
import picture01 from "../img/cont/4.png";

function Hero01() {
  return (
    <div className="Hero01 section">
      <div className="container">
        <div className="Hero01-box">
          <div>
            <h1>SUIAMESE: </h1>
            <h2> The SUI-perstar of SUI Chain.</h2>
          </div>
          <img src={picture01} alt="picture"></img>
        </div>
      </div>
    </div>
  );
}

export default Hero01;
